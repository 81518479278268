<template>
  <div class="historyBox">
    <!-- <Header></Header> -->
    <div class="historyList">
      <div class="infinite-list-wrapper">
        <!-- <ul class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
        <van-empty
          :image="require('@/assets/null.svg')"
          v-if="historyList.length == 0"
        />
        <van-list
          v-else
          class="list"
          v-model="lazyLoading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
          <div
            v-for="(item, index) in historyList"
            :key="index"
            class="list-item"
            @click="goPage(item.site_url)"
          >
            <van-swipe-cell>
              <div class="imgbox">
                <img :src="item.page_style_url" />
              </div>
              <div class="right">
                <p>{{ item.title }}</p>
                <div class="center">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 6px">{{
                    item.interview_time
                  }}</span>
                </div>
                <div class="bottom">
                  <span>{{ item.view_count }} 浏览</span>
                  <span v-if="item.status == 0">未收藏</span>
                  <span v-if="item.status == 1">进行中</span>
                  <span v-if="item.status == 2">已结束</span>
                  <span v-if="item.status == 3">已收藏</span>
                </div>
              </div>
              <template #right>
                <van-button
                  @click="delData(item)"
                  square
                  type="danger"
                  text="删除"
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>
          </div>
          <!-- </ul> -->
        </van-list>
        <!-- <p class="p_height" v-if="loading" style="font-size: 12px; width:100%; text-align: center;">加载中...</p>
                <p class="p_height" v-if="noMore" style="font-size: 12px; width:100%; text-align: center;">
                    <img v-if="historyList.length == 0" class="data_null" src="../../assets/null.svg" alt="">
                    <span v-else>没有更多了</span>
                </p> -->
      </div>
      <Support></Support>
      <sideBar></sideBar>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header";
import Support from "@/components/support";
import sideBar from "@/components/sidebar";
export default {
  components: {
    Header,
    sideBar,
    Support,
  },
  data() {
    return {
      historyData: {
        page: 1,
        page_num: 15,
      },
      loading: false,
      historyList: [],
      total: 0,
      count: 0,
      showNone: true,
      noMore: false,
      lazyLoading: false, //懒加载
      finished: false,
    };
  },
  computed: {
    //   noMore () {
    //     return this.historyList.length >= this.total
    //   },
  },
  mounted() {
    this.getHistoryList();
    // document.querySelector('.suspend-btn .home-box').style.display = 'none'
  },
  methods: {
    goPage(url) {
      location.href = url;
    },
    async getHistoryList() {
      const res = await this.$store.dispatch(
        "list_history_record",
        this.historyData
      );
      if (res.data.code == 200) {
        if (Array.isArray(res.data.data)) {
          this.historyList = [];
          // this.historyList =[...this.historyList, ...res.data.data]
        } else {
          if (this.historyList.length == 0) {
            this.historyList = res.data.data.data;
          } else {
            this.historyList = [...this.historyList, ...res.data.data.data];
          }
          this.total = res.data.data.total;
          this.count += this.historyData.page_num;
          this.lazyLoading = false;
        }

        // if(res.data.data.data.length == 0 && this.historyList.length ==0){
        //     this.total = 0
        //     this.loading = false
        //     this.showNone = false
        // }else{
        //     this.historyList.push(...res.data.data.data)
        //     this.total = res.data.data.total
        //     this.loading = false
        // }
      }
    },
    load() {
      if (this.historyList.length > this.total - 1) {
        this.loading = true;
        setTimeout(() => {
          this.historyData.page++;
          this.getHistoryList();
        }, 0);
      } else {
        this.noMore = true;
      }
    },
    onLoad() {
      if (this.total > this.count) {
        this.lazyLoading = true;
        setTimeout(() => {
          this.historyData.page += 1;
          this.getHistoryList();
        }, 1000);
      } else {
        this.finished = true;
      }
    },
    async delData(val) {
      let obj = {
        site_id: val.site_id,
        site_url: val.site_url.split("event/")[1],
      };
      if (val.site_url.indexOf("event/") == -1) {
        obj.site_url = val.site_url;
      }
      const res = await this.$store.dispatch("delete_history_record", obj);
      if (res.data.code == 200) {
        let arr = [];
        this.historyList.forEach((item) => {
          if (item.site_id != val.site_id) {
            arr.push(item);
          }
        });
        this.historyList = arr;
      } else {
        this.$toast(res.data.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.van-empty,
/deep/.van-loading {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.van-empty {
  width: 100%;
  .van-empty__image {
    width: 300px;
    height: 300px;
  }
}
/deep/.van-list__finished-text {
  margin-top: 10px;
}
/deep/.van-list__loading {
  .van-loading {
    position: relative !important;
  }
}
/deep/ .van-swipe-cell__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 0.08rem;
  .delete-button {
    height: 0.38rem;
    width: 0.6rem;
    border-radius: 0.03rem;
    font-size: 0.12rem;
  }
  /deep/ .van-button--normal {
    padding: 0;
  }
}
.historyBox {
  width: 100%;
  height: calc(100% - 0.44rem);
  background: #f7f8fa;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  .historyList {
    width: 100%;
    height: 100%;
    background: #ffffff;
    overflow: hidden;
    overflow-y: scroll;
    // position: absolute;
    top: 0;
    position: relative;
    .infinite-list-wrapper {
      min-height: calc(100% - 36px);
    }
    /deep/ .van-swipe-cell {
      width: 100%;
      .van-swipe-cell__wrapper {
        display: flex;
        justify-content: space-between;
      }
    }
    .data_null {
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .p_height {
      //   height: 0.7rem;
      line-height: 0.7rem;
      font-size: 0.12rem;
      img {
        display: inline-block;
        position: fixed;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    .list {
      width: 94%;
      margin: 0 auto;
      .list-item {
        display: flex;
        justify-content: flex-start;
        padding: 12px 8px;
        box-sizing: border-box;
        .imgbox {
          width: 110px;
          height: 61px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }
          // margin-right: 0.07rem;
        }
        .right {
          margin-left: 10px;
          box-sizing: border-box;
          flex: 1;
          padding-right: 10px;
          p {
            font-size: 14px;
            color: #000;
            height: 20px;
            line-height: 20px;
            display: -webkit-box;
            display: -moz-box;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; /*显示行数*/
          }
          .center {
            color: #969799;
            font-size: 12px;
            height: 18px;
            line-height: 18px;
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            color: #969799;
            font-size: 12px;
            height: 18px;
            line-height: 18px;
          }
        }
      }
    }
    .tips {
      width: 100%;
      height: 0.6rem;
      font-size: 0.14rem;
      display: block;
      text-align: center;
      line-height: 0.6rem;
      color: #999;
    }
  }
}
</style>
